<template>
  <base-view title="Propiedades" icon="package">
    <div>
      <check-authorization :requiresAuthorizations="['indice propiedades']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Propiedades

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear propiedades']"
                :overrideIf="$store.getters['propiedadModule/isEditingResource']"
              >
                <propiedad-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Search -->
              <propiedad-search></propiedad-search>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <propiedad-list @on-got-item="showForm = true"></propiedad-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import PropiedadForm from '@/components/Operaciones/Propiedades/PropiedadForm'
import PropiedadList from '@/components/Operaciones/Propiedades/PropiedadList'
import PropiedadSearch from '@/components/Operaciones/Propiedades/PropiedadSearch'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'propiedadModule'

export default {
  name: 'PropiedadesMainView',

  components: {
    PropiedadForm,
    PropiedadList,
    PropiedadSearch
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
