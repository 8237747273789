<template>
  <div>
    <!-- Table -->
    <b-table
      id="propiedadesTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Nombre -->
      <template #cell(propietario)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.dueno.persona.nombre_completo }}</span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="propiedadModule/getResourceToEdit"
            deletePath="propiedadModule/deleteResource"
            :editPermissions="['actualizar propiedades']"
            :deletePermissions="['eliminar propiedades']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Datos de la propiedad"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="height: 300px; overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      <b>Subcondominio:</b>
                      {{ row.item.subcondominio }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Tipo:</b>
                      {{ row.item.tipo }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>No. catastral:</b>
                      {{ row.item.no_catastro }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Edificio:</b>
                      {{ row.item.edificio }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>No. puerta:</b>
                      {{ row.item.no_puerta }}
                    </b-list-group-item>

                  </b-list-group>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card
                  no-body
                  header="Propietario/Responsable"
                  header-bg-variant="warning"
                  header-text-variant="light"
                  border-variant="warning"
                  style="height: 300px; overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      <b>Propietario:</b>
                      {{ row.item.dueno.persona.nombre_completo }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Correo:</b>
                      {{ row.item.dueno.email }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Responsable:</b>
                      {{ row.item.responsable.persona.nombre_completo }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Correo:</b>
                      {{ row.item.responsable.email }}
                    </b-list-group-item>

                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE_MODULE = 'propiedadModule'

export default {
  name: 'PropiedadList',

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'no_catastro', label: 'No. catastral', sortable: true },
        { key: 'edificio', label: 'Edificio', sortable: true },
        { key: 'no_puerta', label: 'Puerta', sortable: true },
        { key: 'propietario', label: 'Propietario', sortable: true },
        { key: 'show_details', label: 'Detalle' },
        { key: 'acciones', label: 'Acciones' }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Propiedades')
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>

<style scoped>

</style>
